import request from '@/api/request'

export function fetchCategory(params) {
    return request({
        url: "/office-service/docCategory/list",
        params
    })
}

export function fetchList(data) {
    return request({
        url: "/office-service/docAdapter/list",
        method: "post",
        data,
    })
}

export function add(data) {
    return request({
        url: '/office-service/docAdapter/insert',
        method: 'post',
        data
    })
}

export function edit(data) {
    return request({
        url: '/office-service/docAdapter/update',
        method: 'post',
        data
    })
}

export function remove(data) {
    return request({
        url: '/office-service/docAdapter/delete',
        method: 'post',
        data,
    })
}

export function fetchDetail(data) {
    return request({
        url: '/office-service/docAdapter/query',
        method: 'post',
        data
    })
}