<template>
  <div>
    <Pane />

    <div class="container">
      <a-descriptions :column="2" bordered size="small">
        <a-descriptions-item label="分类">
          {{ category.categoryName }}
        </a-descriptions-item>
        <a-descriptions-item label="子分类">
          {{ category.subcategoryName }}
        </a-descriptions-item>
        <a-descriptions-item label="名称">
          {{ detail.name }}
        </a-descriptions-item>

        <!-- 证书 -->
        <template v-if="detail.pid === '1'">
          <a-descriptions-item label="级别">
            <DataDictFinder dictType="cert_level" :dictValue="detail.level" />
          </a-descriptions-item>

          <a-descriptions-item label="取得时间" :span="2">
            {{ detail.dateAchieve }}
          </a-descriptions-item>
        </template>

        <!-- 质量体系文件 -->
        <template v-if="detail.pid === '2'">
          <a-descriptions-item label="类型">
            <DataDictFinder dictType="doc_document_type" :dictValue="detail.type" />
          </a-descriptions-item>

          <a-descriptions-item label="编号">
            {{ detail.code }}
          </a-descriptions-item>

          <a-descriptions-item label="版本">
            {{ detail.version }}
          </a-descriptions-item>
        </template>

        <!-- 质量管理制度 -->
        <template v-if="detail.pid === '4'">
          <a-descriptions-item label="发文号">
            {{ detail.publishNum }}
          </a-descriptions-item>

          <a-descriptions-item label="发布日期">
            {{ detail.datePublish }}
          </a-descriptions-item>
        </template>

        <!-- 科研成果 -->
        <template v-if="detail.pid === '5'">
          <a-descriptions-item label="主题词">
            {{ detail.subject }}
          </a-descriptions-item>
          <a-descriptions-item label="项目负责人">
            {{ detail.charger }}
          </a-descriptions-item>
          <a-descriptions-item label="主承担部门">
            {{ detail.chargerDept }}
          </a-descriptions-item>
          <a-descriptions-item label="立项日期">
            {{ detail.dateSetup }}
          </a-descriptions-item>
          <a-descriptions-item label="完成日期">
            {{ detail.dateFinish }}
          </a-descriptions-item>
        </template>

        <!-- 专利：发明/实用新型/外观 -->
        <template v-if="['6', '7', '8'].includes(detail.pid)">
          <a-descriptions-item label="专利号">
            {{ detail.number }}
          </a-descriptions-item>
          <a-descriptions-item label="是否有效">
            {{ detail.inforce }}
            <span v-if="detail.inforce === 0">否</span>
            <span v-if="detail.inforce === 1">是</span>
          </a-descriptions-item>
          <a-descriptions-item label="专利权人">
            {{ detail.patentee }}
          </a-descriptions-item>
          <a-descriptions-item label="发明人">
            {{ detail.inventor }}
          </a-descriptions-item>
          <a-descriptions-item label="专利申请日">
            {{ detail.dateApply }}
          </a-descriptions-item>
          <a-descriptions-item label="专利授权日">
            {{ detail.dateAuthorization }}
          </a-descriptions-item>
        </template>

        <!-- 著作权 -->
        <template v-if="detail.pid === '9'">
          <a-descriptions-item label="登记号">
            {{ detail.number }}
          </a-descriptions-item>
          <a-descriptions-item label="作品类别">
            <DataDictFinder dictType="doc_works_cate" :dictValue="detail.type" />
          </a-descriptions-item>
          <a-descriptions-item label="作者">
            {{ detail.authorName }}
          </a-descriptions-item>
          <a-descriptions-item label="著作权人">
            {{ detail.copyrightOwner }}
          </a-descriptions-item>

          <a-descriptions-item label="登记状态">
            <DataDictFinder dictType="doc_registration" :dictValue="detail.registration" />
          </a-descriptions-item>
          <a-descriptions-item label="创作完成日期">
            {{ detail.dateCompletion }}
          </a-descriptions-item>
          <a-descriptions-item label="初次发布日期">
            {{ detail.dateFirstPublish }}
          </a-descriptions-item>
        </template>

        <!-- 工法 -->
        <template v-if="detail.pid === '10'">
          <a-descriptions-item label="工法编号">
            {{ detail.code }}
          </a-descriptions-item>

          <a-descriptions-item label="批准文号">
            {{ detail.approvalNumber }}
          </a-descriptions-item>

          <a-descriptions-item label="等级">
            <DataDictFinder dictType="doc_law_level" :dictValue="detail.level" />
          </a-descriptions-item>

          <a-descriptions-item label="完成单位">
            {{ detail.deptName }}
          </a-descriptions-item>

          <a-descriptions-item label="取得时间">
            {{ detail.dateHold }}
          </a-descriptions-item>

          <a-descriptions-item label="工法完成人">
            {{ detail.people }}
          </a-descriptions-item>
        </template>
        <!-- 项目奖项 -->
        <template v-if="detail.pid === '11'">
          <a-descriptions-item label="获奖项目">
            {{ detail.projectName }}
          </a-descriptions-item>

          <a-descriptions-item label="项目类型">
            <DataDictFinder dictType="doc_prj_cate" :dictValue="detail.projectType" />
          </a-descriptions-item>

          <a-descriptions-item label="项目负责人">
            {{ detail.charger }}
          </a-descriptions-item>

          <a-descriptions-item label="奖项级别">
            <DataDictFinder dictType="doc_award_level" :dictValue="detail.level" />
          </a-descriptions-item>

          <a-descriptions-item label="获奖人员">
            {{ detail.winner }}
          </a-descriptions-item>

          <a-descriptions-item label="评奖机构">
            {{ detail.awardOrg }}
          </a-descriptions-item>
          <a-descriptions-item label="获奖时间">
            {{ detail.dateAward }}
          </a-descriptions-item>
        </template>
        <!-- 科技奖项 -->
        <template v-if="detail.pid === '25'">
          <a-descriptions-item label="奖项级别">
            <DataDictFinder dictType="doc_award_ad" :dictValue="detail.award" />
          </a-descriptions-item>

          <a-descriptions-item label="获奖人员">
            {{ detail.winner }}
          </a-descriptions-item>

          <a-descriptions-item label="评奖机构">
            {{ detail.awardOrg }}
          </a-descriptions-item>
          <a-descriptions-item label="获奖时间">
            {{ detail.dateAward }}
          </a-descriptions-item>
        </template>
        <!-- 企业荣誉 -->
        <template v-if="detail.pid === '12'">
          <a-descriptions-item label="荣誉等级">
            <DataDictFinder dictType="doc_award_level" :dictValue="detail.level" />
          </a-descriptions-item>

          <a-descriptions-item label="获奖时间">
            {{ detail.dateAward }}
          </a-descriptions-item>

          <a-descriptions-item label="评奖机构">
            {{ detail.awardOrg }}
          </a-descriptions-item>
        </template>

        <!-- 专家库 -->
        <template v-if="detail.pid === '13'">
          <a-descriptions-item label="专家类型">
            <DataDictFinder dictType="doc_law_level" :dictValue="detail.expType" />
          </a-descriptions-item>

          <a-descriptions-item label="擅长领域">
            {{ detail.expArea }}
          </a-descriptions-item>

          <a-descriptions-item label="开始聘任时间">
            {{ detail.dateEngageFrom }}
          </a-descriptions-item>

          <a-descriptions-item label="截至聘任时间">
            {{ detail.dateEngageTo }}
          </a-descriptions-item>
        </template>
        <!-- 企业标准图集 -->
        <template v-if="detail.pid === '14'">
          <a-descriptions-item label="图集编号">
            {{ detail.number }}
          </a-descriptions-item>

          <a-descriptions-item label="完成单位">
            {{ detail.orgComplete }}
          </a-descriptions-item>

          <a-descriptions-item label="专业类型">
            {{ detail.major }}
          </a-descriptions-item>

          <a-descriptions-item label="发布日期">
            {{ detail.datePublish }}
          </a-descriptions-item>
        </template>
        <!-- 主编标准/参编标准 -->
        <template v-if="detail.pid === '15' || detail.pid === '16'">
          <a-descriptions-item label="标准编号">
            {{ detail.number }}
          </a-descriptions-item>

          <a-descriptions-item label="参编人">
            {{ detail.drafter }}
          </a-descriptions-item>

          <a-descriptions-item label="类型">
            <DataDictFinder dictType="doc_std_level" :dictValue="detail.type" />
          </a-descriptions-item>

          <a-descriptions-item label="专业类型">
            <DataDictFinder dictType="majorTypes" :dictValue="detail.major" />
          </a-descriptions-item>

          <a-descriptions-item label="发布日期">
            {{ detail.datePublish }}
          </a-descriptions-item>
          <a-descriptions-item label="实施日期">
            {{ detail.dateImplement }}
          </a-descriptions-item>

          <a-descriptions-item label="发布单位">
            {{ detail.orgPublish }}
          </a-descriptions-item>
        </template>

        <a-descriptions-item label="说明" :span="2" v-if="detail.pid !== '5' && detail.pid !== '2'">
          {{ detail.remark }}
        </a-descriptions-item>
        <a-descriptions-item label="附件" :span="2" v-if="detail.pid !== '5'">
          <a v-for="(item, index) in detail.attachmentList" :key="index" target="_blank" :href="item.completePath"
            style="padding-right: 8px">
            {{ item.name }}
          </a>
        </a-descriptions-item>
      </a-descriptions>
      <div v-if="detail.pid === '5'">
        <div class="title">科研成果</div>

        <a-table bordered :data-source="
          Array.isArray(detail.docQcResearchAchievment)
            ? detail.docQcResearchAchievment
            : []
        " :pagination="false">
          <a-table-column title="名称" data-index="name" />
          <a-table-column title="类型">
            <template slot-scope="text">
              <DataDictFinder dictType="doc_achievement_type" :dictValue="text.type" />
            </template>
          </a-table-column>
          <a-table-column title="说明" data-index="remark" />
          <a-table-column title="附件">
            <template slot-scope="text">
              <div v-if="Array.isArray(text.attachmentList)">
                <a target="_blank" v-for="(item, index) in text.attachmentList" :key="index" :href="item.completePath"
                  style="padding-right: 8px">{{ item.name }}</a>
              </div>
              <div v-else>--</div>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchCategory, fetchDetail } from "@/api/knowledge";
export default {
  data() {
    return {
      categoryList: [],
      detail: {},
    };
  },

  computed: {
    category() {
      let categoryName = "--",
        subcategoryName = "--";
      this.categoryList.forEach((item) => {
        if (item.children) {
          const obj = item.children.find(
            (element) => element.id === this.detail.pid
          );
          if (obj) {
            subcategoryName = obj.subCategory;
            categoryName = item.name;
          }
        }
      });
      return {
        categoryName,
        subcategoryName,
      };
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id, pid } = query || {};
    fetchDetail({ id, pid }).then((res) => {
      this.detail = res ?? {};
    });

    fetchCategory().then((res) => {
      if (res) {
        this.categoryList = Object.keys(res).map((name) => {
          return {
            name,
            children: res[name],
          };
        });
      }
    });
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.title {
  font-weight: bold;
  margin: 12px 0;
}
</style>
